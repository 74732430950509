// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
$tooltip-bg: rgba(43, 34, 42, 0.9);
$default-green: #00C344;
$line-bg: #777;
$balance-table-small: #aaa;
$ibox-emphasis: #e0e0e0;
$default-red: #ed5565;
$primary-dark: #263E4B;
$primary-dark-text: #A4B3BC;
$secondary-dark-text: #5E6A71;
$moeb: #0093FF;
$moeb2: #30BAD9;
$roeb: #484C93;
$error: #fc030f;

@font-face {
  font-family: 'MuseoSans';
  src: url('/fonts/MuseoSans-500.woff') format('woff');
}
body.md-skin {
  font-family: 'MuseoSans' !important;
  font-size: 16px;
  color: $secondary-dark-text;
}

.md-skin {
  .navbar-top-links li {
    float: left;
  }
  h2 {
    color: $default-green;
  }

  h2.error {
    color: $error;
  }

  .boeb-color {
    color: $default-green;
  }

  .moeb-color {
    color:$moeb;
  }

  .moeb2-color {
    color:$moeb2;
  }

  .roeb-color {
    color:$roeb;
  }
  .btn.btn-primary {
    background-color: $default-green;
    border-color:$default-green;
    color: #ffffff;
    font-size: 18px;
  }
  .btn-outline.btn-primary {
    background-color: inherit;
    color: $default-green;
    font-size: 15px;
  }
  .btn-outline.btn-primary:hover {
    background-color: $default-green;
    color: #ffffff;
  }

  .ibox {
    box-shadow: none;
  }

  .nav-header {
    background: $primary-dark none;
    .text-muted {
      color: $primary-dark-text;
    }
  }

  .navbar-static-side {
    box-shadow: none;
  }

  .navbar-static-top {
    box-shadow: none;
  }

  .sidebar-collapse {
    background: $primary-dark none;
  }

  .nav > li > a {
    color: $primary-dark-text;
    font-weight: 500;
  }
  .nav > li > a  i{
    margin-right: 16px;
  }

  .nav.navbar-right > li > a {
    font-size: 18px;
    color:$primary-dark-text;
    text-align: center;
  }

  .boeb-logo {
    width: 220px;
    height: 220px;
  }

  .btwlookup {
    width: 400px;

  }

  .form-group {
    margin-top: 16px;
  }

  .form-control {
    font-size: 18px;
  }

  .step-text {
    font-size: 24px;
    color:$default-green;
    font-weight: bold;
    margin-left: -16px;
  }
  .top-select {
  }

  .top-select .top-selector {
    margin-left: 10px;
    color: $secondary-dark-text;
    text-align: center;
    display: inline-block;
    height: 28px;
    .top-selector-tpe {
      position: relative;
      top:-8px;
    }
  }

  .boeb-select {
    margin-right: 32px;
  }

  .boeb-select .boeb-selector {
    margin-left: 40px;
    margin-bottom: 20px;
    color: $secondary-dark-text;
    text-align: center;
    display: inline-block;
    height: 28px;
    .boeb-selector-tpe {
      margin-left: 32px;
      position: relative;
      top:-8px;
    }
  }
}

.flex-top-bar {
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.flex-moeb-percent {
  margin-top: 16px;
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.flex-spacer {
  flex-grow: 1;
}

.flex-horizontal {
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.fa-svg-add {
  width: 28px;
  height: 28px;
  background: url("/img/078-add.svg");
  background-size: 28px 28px;
}

.fa-svg-logout {
  width: 28px;
  height: 28px;
  background: url("/img/004-logout.svg");
  background-size: 28px 28px;
}

.fa-svg-look {
  width: 20px;
  height: 20px;
  background: url("/img/001-view.svg");
  background-size: 20px 20px;
}

.fa-svg-garbage {
  width: 18px;
  height: 18px;
  background: url("/img/035-garbage.svg");
  background-size: 18px 18px;
}

.fa-svg-download {
  width: 18px;
  height: 18px;
  background: url("/img/017-download-1.svg");
  background-size: 18px 18px;
}

.fa-svg-search {
  width: 18px;
  height: 18px;
  background: url("/img/036-search.svg");
  background-size: 18px 18px;
}
.fa-svg-home {
  width: 28px;
  height: 28px;
  background: url("/img/070-home.svg");
  background-size: 28px 28px;
}

.fa-svg-menu {
  width: 28px;
  height: 28px;
  background: url("/img/006-menu-bar.svg");
  background-size: 28px 28px;
}

.fa-svg-mail {
  width: 28px;
  height: 28px;
  background: url("/img/045-mail.svg");
  background-size: 28px 28px;
}

.fa-svg-list {
  width: 28px;
  height: 28px;
  background: url("/img/060-list.svg");
  background-size: 28px 28px;
}

.fa-svg-file {
  width: 28px;
  height: 28px;
  background: url("/img/018-file.svg");
  background-size: 28px 28px;
}

.fa-svg-folder {
  width: 28px;
  height: 28px;
  background: url("/img/015-folder.svg");
  background-size: 28px 28px;
}
.fa-svg-gear {
  width: 28px;
  height: 28px;
  background: url("/img/040-gear-2.svg");
  background-size: 28px 28px;
}

.fa-svg-graph {
  width: 28px;
  height: 28px;
  background: url("/img/044-graph.svg");
  background-size: 28px 28px;
}

.fa-svg-agenda {
  width: 28px;
  height: 28px;
  background: url("/img/052-agenda.svg");
  background-size: 28px 28px;
}

.fa-svg-user {
  width: 28px;
  height: 28px;
  background: url("/img/073-user.svg");
  background-size: 28px 28px;
}

.fa-svg-cursor {
  width: 28px;
  height: 28px;
  background: url("/img/062-cursor.svg");
  background-size: 28px 28px;
}

.fa-svg-info {
  width: 28px;
  height: 28px;
  background: url("/img/051-information.svg");
  background-size: 28px 28px;
}
.nav-label {
  font-size: 18px;
  top: 15px;
  position: absolute;
  margin:auto;
}

.schema-html {
  .line {
    padding: 0;
    margin: 0;
    height: 5px;
    background-color: $line-bg;
    line-height: 1px;
    position: absolute;
  }

  .greenline {
    background-color: $default-green;
  }

  .triangle {
    position: absolute;
    border-style: solid;
    border-color: transparent transparent transparent $default-green;
    width: 0;
    height: 0;
  }

  .triangle-left {
    border-width: 5px 10px 5px 0;
    border-color: transparent $default-green transparent transparent;
  }

  .triangle-right {
    border-width: 5px 0 5px 10px;
    border-color: transparent transparent transparent $default-green;
  }

  .md-skin .ibox {
    margin-bottom: 10px;
  }

  .balance-table {
    width: 100%;
  }

  .balance-table td {
    padding: 0 0 10px;
  }

  .statement .balance-table td {
    padding: 0;
  }

  .balance-table small {
    color: $balance-table-small;
  }

  .balance-table ul {
    margin: 0;
    padding-left: 20px;
  }

  .p-xxs {
    padding: 5px 10px;
  }

  .p-top-none {
    padding-top: 0 !important;
  }

  .p-bottom-none {
    padding-bottom: 0 !important;
  }

  .ibox-emphasis {
    background: $ibox-emphasis;
    border: 1px solid $default-green !important;
  }

  .ibox-emphasis-negative {
    background: $ibox-emphasis;
    border: 1px solid #ed5565 !important;
  }

  .ibox-red-emphasis {
    background: #ed5565;
    color: #fff;
  }

  .ibox-green-emphasis .text-boeb,
  .ibox-red-emphasis .text-danger {
    color: #fff;
  }

  .text-boeb {
    color: $default-green;
  }

  .boeb-bg,
  .ibox-green-emphasis {
    background-color: $default-green;
    color: #ffffff;
  }

  .preceding-year {
    font-size: smaller;
    font-style: italic;
  }

  .ibox-green-emphasis .preceding-year {
    color: #ffffff;
  }

  .data--left {
    position: relative;

    .line-horizontal {
      width: calc(100% - 5px);
      height: 7px;

      &.line--top {
        top: 0;
      }

      &.line--bottom {
        bottom: 0;
      }
    }

    .line-vertical {
      width: 7px;
      height: calc(100% - 5px);

      &.line--center {
        left: calc(50% - 6px);
      }
    }
  }

  .schema--result {
    position: relative;

    .schema--calculation-arrow {
      .line-vertical {
        left: 60px;
        top: 81px;
        width: 3px;
        height: 20px;
        z-index: -1;
      }

      .line-horizontal {
        left: 60px;
        top: 100px;
        height: 3px;
        width: calc(50% - 65px);
      }

      .triangle.triangle-right {
        top: 97px;
        left: calc(50% - 10px);
      }
    }
  }

  .pos-rel {
    position: relative;
  }

  .text--align-right {
    text-align: right;
  }

  .tooltip {
    display: none;
    text-align: left;
    color: white;
    border-radius: 3px;
    padding: 10px 15px;
    white-space: nowrap;
    width: auto;
    background-color: $tooltip-bg;
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -35%);

    &::after {
      position: absolute;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid $tooltip-bg;
      content: " ";
      font-size: 0;
      line-height: 0;
      margin-left: -5px;
      width: 0;
      bottom: -5px;
      left: 50%;
    }

    ul {
      list-style-type: none;
      padding-left: 0;

      li {
        line-height: 17px;
        font-weight: bold;
        font-size: 13px;

        span.positive-revenue {
          color: $default-green;

          span.text-boeb {
            color: $default-green;
          }
        }

        span.negative-revenue {
          color: $default-red;

          span.text-boeb {
            color: $default-red;
          }
        }
      }
    }
  }

  .tooltip-hover {
    cursor: pointer;
    display: block;
    float: right;
    position: relative;

    &:hover {
      .tooltip {
        display: block;
      }
    }
  }
}
